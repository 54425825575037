.fondo-header {
  background-color: rgb(208, 4, 4);
}

.header-text {
  color: rgb(233, 237, 26);
}

.menubtn {
  cursor: pointer;
  padding: 0px 8px;
  color: rgb(255, 255, 0);
}
.color-icon-menu {
  color: rgb(102, 1, 1);
}
.menu-container {
  padding: 0px 0px;
  height: 100vh;
  background-color: rgb(208, 4, 4) !important;
}
/* .MuiPaper-root {
  background-color: rgb(208, 4, 4) !important;
} */
.menu-item {
  padding: 7px 0px 7px 10px;
  color:  rgb(208, 211, 0);
  cursor: pointer;
}
.menu-item:hover {
  color:  rgb(255, 255, 255);
  background-color: rgb(170, 0, 0);
}
.menu-item:hover>.color-icon-menu {
  color: rgb(234, 0, 0);
}
.selected-item {
  background-color: rgb(184, 1, 1);
}
.spacing {
  margin-left: 10px;
}
hr {
  height: 2px;
  background-color: rgb(184, 0, 0);
}

.menu-footer-version {
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-shadow: 0px 0px 6px yellow;
}
input[type="file"]#file {
  top: -25px;
  margin: auto;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
#secondfile {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.txt-menu {
  text-shadow: 1px 1px 1px rgb(173, 173, 173);
}
.btn-float {
  margin: 5px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px;
  width:70px;
  background-color: rgb(215, 215, 215);
  text-decoration: none;
}
.btn-float > p{
  text-decoration: none;
  margin: 0px;
  font-size: small;
}
.fondo_border_shadow {
  background-color: rgba(255, 255, 255, 0.552);
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.516),-5px -5px 2px rgba(0, 0, 0, 0.516) inset,5px 5px 4px rgb(255, 255, 255) inset;
}
.hoverTable:hover{
  background-color: rgb(227, 227, 227);
}
.small-font {
  font-size: small;
}
.loaderFullpage {
  height: 100vh;
  width: 100%;
  background-color: rgba(201, 2, 2, 0.535);
  position: absolute;
  top: 0px;
}
.center {
  background: yellow;
  position: absolute;
  top:50%;
  right: 50%;
}

.footer-bar {
  position: fixed !important;
  bottom: 0px !important;
}
.centerAll {
  padding: auto;
  margin: auto;
}